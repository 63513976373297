import React from 'react';
import Home from './screens/Home';
import { Route, Routes } from 'react-router-dom';
import Products from './screens/Products';
import Producers from './screens/Producers';
import Customers from './screens/Customers';
import Footer from './component/Footer';
import Contact from './screens/Contact';
import ScrollToTop from './component/ScrollToTop';
import CookieConsent from 'react-cookie-consent';
function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/our_products" element={<Products />} />
        <Route exact path="/our_producers" element={<Producers />} />
        <Route exact path="/our_customers" element={<Customers />} />
        <Route exact path="/contact_us" element={<Contact />} />
      </Routes>
      <Footer />

      <CookieConsent
        debug={true}
        location="bottom"
        buttonStyle={{
          color: '#000000',
          fontSize: '14px',
          border: 'solid 1px transparent',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #FF3939, #FF8139)',

          boxShadow: '2px 1000px 1px #fff inset',
          borderRadius: 20,
        }}
        style={{
          alignItems: 'center',
          background: '#F5F5F5',
          color: '#000000',
          fontFamily: 'Raleway',
          opacity: 0.9,
        }}
        buttonText=" I agree"
        expires={150}
      >
        We use cookies to ensure that we give you the best experience on our
        website. If you continue to use this site we will assume that you are
        happy with it
      </CookieConsent>
    </>
  );
}

export default App;
