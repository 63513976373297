import React, { useEffect, useState } from 'react';
import Resturant from '../component/Resturant';
import Hero from '../component/Hero';
import '../styles/home.css';
import Contact from '../component/Contact';
import Products from '../component/Products';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../styles/homeNav.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [menu_class, setMenuClass] = useState('menu hidden');
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const updateMenu = () => {
    if (!isMenuClicked) {
      setMenuClass('menu visible');
    } else {
      setMenuClass('menu hidden');
    }
    setIsMenuClicked(!isMenuClicked);
  };

  //Change bav color on srcolling
  const [color, setColor] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [burgerbg, setBurgurbg] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
      setShowNav(true);
      setBurgurbg(true);
    } else {
      setColor(false);
      setShowNav(false);
      setBurgurbg(false);
    }
  };

  window.addEventListener('scroll', changeColor);
  return (
    <div>
      <div className={color ? 'home-nav-con home-nav-con-bg' : 'home-nav-con'}>
        <div className={showNav ? 'home-logo show' : 'home-logo'}>
          <Link to="/">
            <img src="./images/logo.png" alt="logo" />
          </Link>
        </div>
        <div
          className={
            burgerbg ? 'home-burger-menu burger-bg' : 'home-burger-menu'
          }
          onClick={updateMenu}
        >
          {isMenuClicked ? <FiX /> : <GiHamburgerMenu />}
        </div>
        <div className={menu_class}>
          <Link to="/" onClick={updateMenu}>
            Home
          </Link>
          <Link to="/our_products" onClick={updateMenu}>
            Our Products
          </Link>
          <Link to="/our_customers" onClick={updateMenu}>
            Our Customers
          </Link>
          <Link to="/our_producers" onClick={updateMenu}>
            Our Producers
          </Link>
          <Link to="/contact_us" onClick={updateMenu}>
            Contact Us
          </Link>
        </div>
      </div>
      <Hero />
      <div className="home-con">
        <div data-aos="fade-up" className="fst-part">
          <div className="fst-left">
            <div className="home-logo-fst">
              <img src="./images/logo.svg" alt="logo" />
            </div>

            <div className="maneku">
              <img src="./images/maneku.jpg" alt="MANEKU OMAKASE" />
              <p>MANEKU</p>
            </div>
          </div>
          <div className="fst-right">
            <div className="welcome">
              <h1>Welcome to</h1>
              <h1 style={{ fontStyle: 'italic' }}>our service.</h1>
              <p>
                Fine Food Service is an ingredient service provider company in
                Thailand.
                <br />
                <br /> Our company aims to supply varieties of ingredients from
                the cooperative network of ingredient producers around the
                world.
              </p>
            </div>
            <div data-aos="fade-up" className="our-service">
              <h1>Our Service</h1>
              <p>
                Fine Food Service supplies the finest quality ingredients to
                restaurants and hotels all over Thailand.
              </p>
              <br />
              <p>We have expertise in supplying ingredients to</p>
              <ul style={{ marginLeft: '1.5rem' }}>
                <li>Fine dining</li>
                <li>
                  Michelin star &nbsp;
                  <img width={13} src="images/MichelinStar.png" alt="" />
                  &nbsp;
                  <img width={13} src="images/MichelinStar.png" alt="" />
                </li>
                <li>Steakhouse</li>
                <li>Omakase restaurants</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          // data-aos="fade-up" data-aos-duration="500"
          className="resturant"
        >
          <Resturant />
        </div>
        <div
          // data-aos="fade-up"
          // data-aos-anchor-placement="center-bottom"
          className="our-products"
        >
          <h1>Our Products</h1>
          <p>
            Fine Food Service specializes in
            <br /> fine ingredients from our partners from
            <br /> different parts of the world.
          </p>
          <Products data-aos-anchor-placement="bottom-bottom" />
        </div>
        <Contact />
      </div>
    </div>
  );
};

export default Home;
