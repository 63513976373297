import React, { useRef } from 'react';
import '../styles/hero.css';
import { BsChevronDown } from 'react-icons/bs';
const Hero = () => {
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="hero-con">
      <div className="hero">
        <img className="hero-bg" src="./images/headerbg.jpg" alt="hero-bg" />
        <div className="overlay">
          <div className="logo-name">
            <img src="./images/hero-logo.svg" alt="" />
          </div>
          <div className="see-more" onClick={handleClick}>
            <p>Scroll down to see more</p>
            <BsChevronDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
