import React, { useEffect } from 'react';
import '../styles/contactScr.css';
import ContactCom from '../component/Contact';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../component/Navbar';
const Contact = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="contact-con">
        <div className="contact-left">
          <h1 data-aos="fade-right" className="for-customers">
            For Customers contact
          </h1>
          <p data-aos="fade-up">
            <li>Email : Sale@fineserve.com</li>
            <li>Tel : 083-010-1929</li>
            <li>Line : @finefoodservice</li>
          </p>
          <h1 data-aos="fade-right" className="For-Producers">
            For Producers {'&'}
            <br /> Partners contact
          </h1>
          <p data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <li>Email: purchasing@fineserve.com</li>
            <br />
          </p>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          className="contact-right"
        >
          <img src="images/contact.jpg" alt="" />
        </div>
      </div>
      <ContactCom />
    </div>
  );
};

export default Contact;
