import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import '../styles/Navbar.css';
const Navbar = () => {
  const [menu_class, setMenuClass] = useState('menu hidden');
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const updateMenu = () => {
    if (!isMenuClicked) {
      setMenuClass('menu visible');
    } else {
      setMenuClass('menu hidden');
    }
    setIsMenuClicked(!isMenuClicked);
  };

  //Change bav color on srcolling
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener('scroll', changeColor);

  return (
    <div className={color ? 'nav-con nav-con-bg' : 'nav-con'}>
      <div className="logo">
        <Link to="/">
          <img src="./images/logo.png" alt="logo" />
        </Link>
      </div>
      <div className="burger-menu" onClick={updateMenu}>
        {isMenuClicked ? <FiX /> : <GiHamburgerMenu />}
      </div>
      <div className={menu_class}>
        <Link to="/" onClick={updateMenu}>
          Home
        </Link>
        <Link to="/our_products" onClick={updateMenu}>
          Our Products
        </Link>
        <Link to="/our_customers" onClick={updateMenu}>
          Our Customers
        </Link>
        <Link to="/our_producers" onClick={updateMenu}>
          Our Producers
        </Link>
        <Link to="/contact_us" onClick={updateMenu}>
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
