import React, { useEffect } from 'react';
import '../styles/customers.css';
import Resturant from '../component/Resturant';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../component/Navbar';
const Customers = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <div className="customers-con">
        <Navbar />
        <h1 data-aos="fade-up">Our Customers</h1>
        <p data-aos="fade-up">
          Fine Food Service specializes in
          <br /> fine ingredients from our partners from
          <br /> different parts of the world.
        </p>
        <div data-aos="fade-up" data-aos-duration="3000" className="resturant">
          <Resturant />
        </div>
        <div className="customers-wrapper">
          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c1.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r1.jpg" alt="" />
              </div>
              <div>
                <h1>Sexy Cow</h1>
                <p>A4 Black Bean Wagyu</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c2.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r2.jpg" alt="" />
              </div>
              <div>
                <h1>Kensho</h1>
                <p>
                  A4 Black Bean Wagyu
                  <br />
                  Black & White Truffle
                </p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c3.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r3.jpg" alt="" />
              </div>
              <div>
                <h1>Isola</h1>
                <p>
                  White Truffle
                  <br />
                  A4 Black Bean Wagyu
                </p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c4.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r4.jpg" alt="" />
              </div>
              <div>
                <h1>Monosei</h1>
                <p>A5 Black Bean Wagyu</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c5.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r5.jpg" alt="" />
              </div>
              <div>
                <h1>Maneku</h1>
                <p>A5 Black Bean Wagyu</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c6.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r6.jpg" alt="" />
              </div>
              <div>
                <h1>Shibui</h1>
                <p>A5 Black Bean Wagyu</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c7.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r7.jpg" alt="" />
              </div>
              <div>
                <h1>Featherstone</h1>
                <p>Black Truffle Paste</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c8.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r8.jpg" alt="" />
              </div>
              <div>
                <h1>H.O.N</h1>
                <p>Matcha & Houjicha</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c9.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r9.jpg" alt="" />
              </div>
              <div>
                <h1>Mokusai House</h1>
                <p>
                  Genmaicha powder
                  <br />
                  A5 Black Bean Wagyu
                </p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c10.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r10.jpg" alt="" />
              </div>
              <div>
                <h1>Chai Jia Chai</h1>
                <p>A5 Black Bean Wagyu</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c11.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r11.jpg" alt="" />
              </div>
              <div>
                <h1>Valse Studio</h1>
                <p>A4 Black Bean Wagyu</p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="customers">
            <img src="images/customers/c12.jpg" alt="" />
            <div className="customers-info">
              <div className="info-img">
                <img src="/images/r12.jpg" alt="" />
              </div>
              <div>
                <h1>Mensho Tokyo</h1>
                <p>Black & White Truffle</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
