import React from 'react';
import { FaInstagramSquare } from 'react-icons/fa';
import { BsFacebook } from 'react-icons/bs';
import '../styles/footer.css';
const Footer = () => {
  return (
    <div className="footer-con">
      <div className="footer-icons">
        <a
          href="https://www.instagram.com/finefoodservice.th/?hl=th"
          target="popup"
        >
          <FaInstagramSquare />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100089252065514"
          target="popup"
        >
          <BsFacebook />
        </a>
      </div>
      <p>&copy; 2021 Fine Food Service | All right reserved</p>
    </div>
  );
};

export default Footer;
