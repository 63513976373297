import React from 'react';
import Slider from 'react-slick';
import '../styles/resturant.css';
import { restaurants } from '../data.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Resturant = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 10,
    slidesToScroll: 1,
    pauseOnHover: false,

    swipe: false,
    draggable: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,

          speed: 1000,
          autoplaySpeed: 1500,

          swipe: false,
          draggable: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,

          speed: 1000,
          autoplaySpeed: 1500,
          // cssEase: 'linear',
          swipe: false,
          draggable: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          speed: 1000,
          autoplaySpeed: 1500,
          // cssEase: 'linear',
          swipe: false,
          draggable: false,
        },
      },
    ],
  };
  return (
    <div className="rest-con">
      <Slider {...settings}>
        {restaurants.map((res, i) => (
          <div className="slide">
            <img src={res.img} alt="" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Resturant;
