export const products = [
  {
    id: 1,
    img: '/images/products/p1.jpg',
    name: 'Japanese Seafood',
    desc: 'Large varieties of fresh seafood.',
  },
  {
    id: 2,
    img: 'images/products/p2.jpg',
    name: 'Beef',
    desc: 'Premium beef from Japan and Australia.',
  },
  {
    id: 3,
    img: 'images/products/p3.jpg',
    name: 'Lamb',
    desc: 'Selected premium grass-fed lamb.',
  },
  {
    id: 4,
    img: 'images/products/p4.jpg',
    name: 'Tea',
    desc: 'Loose-leaf & powdered tea from Kyoto and Shizuoka.',
  },
  {
    id: 5,
    img: 'images/products/p5.jpg',
    name: 'Salmon',
    desc: 'Freshly imported salmon.',
  },
  {
    id: 6,
    img: 'images/products/p6.jpg',
    name: 'Truffle',
    desc: 'Fresh truffle and truffle product from Italy.',
  },
  {
    id: 7,
    img: 'images/products/p7.jpg',
    name: 'Coffee',
    desc: 'The high-quality coffee beans from different parts of the world',
  },
  {
    id: 8,
    img: 'images/products/p8.jpg',
    name: 'Yuzu',
    desc: 'High quality Yuzu from No.1 Yuzu producers in Kochi perfecture, Japan',
  },
  {
    id: 9,
    img: 'images/products/p9.jpg',
    name: 'Edible Accessory',
    desc: 'Edible flowers from certfied producers & edible glitter',
  },
];

export const producers = [
  {
    id: 1,
    img: 'images/producers/pd1.png',
    name: 'Black Bean Wagyu',
    decs: 'An exclusive wagyu beef brand from Okayama prefecture in Japan. Black Bean Wagyu is a black bean-fed beef which black bean enhances the umami taste in the beef. The brand has both 100% pure blood Japanese wagyu and cross breed wagyu with excellent quality.',
  },
  {
    id: 2,
    img: 'images/producers/pd2.png',
    name: 'Fresh Truffle & Truffle Products',
    decs: 'Sassone Tartufi  is a professional truffle company located in Rocca Imperiale, Italy. The cooperation of the professional hunters in the area combined with the fertile territory in Rocca Imperiale made Sassone Tartufi able to offer fine quality truffles to various restaurants around the world.',
  },
  {
    id: 3,
    img: 'images/producers/pd3.png',
    name: 'Matcha & Houjicha',
    decs: 'Maiko Tea Company is a traditional tea company located in Kyoto, Japan. The company is widely known throughout Japan for producing the highest quality Gyokuro green tea from its 9-time of the highest award-winning prize.',
  },
  {
    id: 4,
    img: 'images/producers/pd4.png',
    name: 'Tea Powder',
    decs: 'Kanagawa Ken Chaho is the traditional Japanese powdered tea manufacturer since 1951. The company has passed on the passion for powdered tea over three generations. The company focuses all their tea selection from Uji, Kyoto.',
  },
];
export const customers = [
  {
    id: 1,
    img: 'images/customers/c1.png',
    logo: 'images/customers/logo/c1.svg',
    name: 'Sexy Cow',
    decs: 'A4 Black Bean Wagyu',
  },
  {
    id: 2,
    img: 'images/customers/c2.png',
    logo: 'images/customers/logo/c2.svg',
    name: 'Kensho',
    decs: 'A4 Black Bean Wagyu Black & White Truffle',
  },
  {
    id: 3,
    img: 'images/customers/c3.png',
    logo: 'images/customers/logo/c3.svg',
    name: 'Isola',
    decs: 'White Truffle, A4 Black Bean Wagyu',
  },
  {
    id: 4,
    img: 'images/customers/c4.png',
    logo: 'images/customers/logo/c4.svg',
    name: 'Monosei',
    decs: 'A5 Black Bean Wagyu',
  },
  {
    id: 5,
    img: 'images/customers/c5.png',
    logo: 'images/customers/logo/c5.svg',
    name: 'Maneku',
    decs: 'A5 Black Bean Wagyu',
  },
  {
    id: 6,
    img: 'images/customers/c6.png',
    logo: 'images/customers/logo/c6.svg',
    name: 'Shibui',
    decs: 'A5 Black Bean Wagyu',
  },
  {
    id: 7,
    img: 'images/customers/c7.png',
    logo: 'images/customers/logo/c7.svg',
    name: 'Featherstone',
    decs: 'Black Truffle Paste',
  },
  {
    id: 8,
    img: 'images/customers/c8.png',
    logo: 'images/customers/logo/c8.svg',
    name: 'H.O.N',
    decs: 'Matcha & Houjicha',
  },
  {
    id: 9,
    img: 'images/customers/c9.png',
    logo: 'images/customers/logo/c9.svg',
    name: 'Mokusai House',
    decs: 'Genmaicha powder, A5 Black Bean Wagyu',
  },
  {
    id: 10,
    img: 'images/customers/c10.png',
    logo: 'images/customers/logo/c10.svg',
    name: 'Chai Jia Chai',
    decs: 'A5 Black Bean Wagyu',
  },
  {
    id: 11,
    img: 'images/customers/c11.png',
    logo: 'images/customers/logo/c11.svg',
    name: 'Valse Studio',
    decs: 'A4 Black Bean Wagyu',
  },
];
export const restaurants = [
  {
    id: 1,
    img: 'images/r1.jpg',
  },
  {
    id: 2,
    img: 'images/r2.jpg',
  },
  {
    id: 3,
    img: 'images/r3.jpg',
  },
  {
    id: 4,
    img: 'images/r4.jpg',
  },
  {
    id: 5,
    img: 'images/r5.jpg',
  },
  {
    id: 6,
    img: 'images/r6.jpg',
  },
  {
    id: 7,
    img: 'images/r7.jpg',
  },
  {
    id: 8,
    img: 'images/r8.jpg',
  },
  {
    id: 11,
    img: 'images/r9.jpg',
  },
  {
    id: 12,
    img: 'images/r10.jpg',
  },
  {
    id: 13,
    img: 'images/r11.jpg',
  },
];
