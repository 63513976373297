import React, { useState } from 'react';
import Slider from 'react-slick';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/product.css';
import { products } from '../data';

const Products = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <SlArrowRight />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <SlArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  // const settings = {
  //   infinite: true,
  //   focusOnSelect: true,
  //   lazyload: true,
  //   spped: 300,
  //   slidesToShow: 5,
  //   initialSlide: 0,
  //   dots: true,
  //   centerMode: true,
  //   centerPadding: 0,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   beforeChange: (current, next) => setImageIndex(next),
  // };
  const settings = {
    focusOnSelect: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    lazyLoad: true,
    slidesToScroll: 5,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1204,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="prducts-list-con">
      <Slider {...settings}>
        {products.map((product, i) => (
          <div className={i === imageIndex ? 'slide activeSlide' : 'slide'}>
            <img src={product.img} alt={product.name} />
            <div className="product-overlay">
              <h1>{product.name}</h1>
              <h3>{product.desc}</h3>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Products;
