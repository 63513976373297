import React, { useEffect } from 'react';
import { producers } from '../data';
import '../styles/producers.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../component/Navbar';
const Producers = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <div className="producers-con">
        <Navbar />
        <h1 data-aos="fade-right">Our Producers</h1>
        <p data-aos="fade-left" className="pd-info">
          We work closely with these producers to distribute and deliver
          <br /> their products to our customers' hands. These are the brands
          <br /> Fine Food Service represents as the sole distributor in
          Thailand
        </p>
        <div className="producers-wrapper">
          {producers.map((pd, index) => (
            <div data-aos="fade-up" className="producers">
              <img src={pd.img} alt={pd.name} />
              <div>
                <h1 style={index === 1 ? { width: '230px' } : {}}>{pd.name}</h1>
                <p>{pd.decs}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Producers;
