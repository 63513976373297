import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/contact.css';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isCooldown, setIsCooldown] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) {
      newErrors.name = 'Please enter your name';
    }
    if (!formData.companyName) {
      newErrors.companyName = 'Please enter your company name';
    }
    if (!formData.email) {
      newErrors.email = 'Please enter your email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!formData.message) {
      newErrors.message = 'Please enter a message';
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateForm();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsCooldown(true);
      emailjs
        .send(
          'service_l8ya9qr',
          'template_fox0idg',
          formData,
          'ZBTRaMIjcdbn2JOgZ'
          // 'YOUR_SERVICE_ID',
          // 'YOUR_TEMPLATE_ID',
          // formData,
          // 'YOUR_USER_ID'
        )
        .then(() => {
          setShowSuccess(true);
          setFormData({
            name: '',
            companyName: '',
            email: '',
            message: '',
          });
          setTimeout(() => {
            setShowSuccess(false);
            setIsCooldown(false);
          }, 2000);
        })
        .catch(() => {
          setShowError(true);
          setIsCooldown(false);
        });
    }
  };
  return (
    <>
      <div className="contact-container">
        <div className="contactUs">
          <section className="contact-container-con">
            <div className="item-left">
              <div className="contact-content-text">
                <h1>Contact Us</h1>
              </div>
            </div>
            <div className="contact-item-right">
              <div className="contact-container-con">
                <form className="input_contact" onSubmit={handleSubmit}>
                  <div>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      placeholder="Enter your name and surname"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && (
                      <div className="msg-error">{errors.name}</div>
                    )}
                  </div>
                  <div>
                    <input
                      id="companyName"
                      type="text"
                      name="companyName"
                      placeholder="Enter your company"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                    {errors.companyName && (
                      <div className="msg-error">{errors.companyName}</div>
                    )}
                  </div>
                  <div>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Enter a valid email address"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <div className="msg-error">{errors.email}</div>
                    )}
                  </div>
                  <div>
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Enter your message"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    {errors.message && (
                      <div className="msg-error">{errors.message}</div>
                    )}
                  </div>
                  <button
                    className="submit-btn"
                    type="submit"
                    disabled={isCooldown}
                  >
                    {isCooldown ? 'Sending...' : 'Submit'}
                  </button>
                </form>
                {showSuccess && (
                  <div className="alert success">
                    <span className="alert-icon success">
                      <BsCheckCircle />
                    </span>
                    <h2 className="alert-message">Message sent!</h2>
                    <p>Your email has successfully been submitted</p>
                    <button
                      className="alert-close success"
                      onClick={() => setShowSuccess(false)}
                    >
                      Continue
                    </button>
                  </div>
                )}
                {showError && (
                  <div className="alert error">
                    <span className="alert-icon error">
                      <BsXCircle />
                    </span>
                    <div className="alert-message">
                      <h2>Retry</h2>
                      <p>
                        We apologize, something went wrong. Please try again.
                      </p>
                    </div>
                    <button
                      className="alert-close error"
                      onClick={() => setShowError(false)}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        {/* <div className="alert error">
          <span className="alert-icon error">
            <BsXCircle />
          </span>
          <div className="alert-message">
            <h1>Retry</h1>
            <p>
              We apologize, something went wrong.
              <br /> Please try again.
            </p>
          </div>

          <button
            className="alert-close error"
            onClick={() => setShowError(false)}
          >
            Continue
          </button>
        </div> */}
      </div>
    </>
  );
}

export default Contact;
