import React, { useEffect } from 'react';
import { products } from '../data';
import '../styles/products.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../component/Navbar';

const Products = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="products-con">
      <Navbar />
      <h1 data-aos="fade-right">Our Products</h1>
      <p data-aos="fade-left">
        Fine Food Service specializes in
        <br /> fine ingredients from our partners from
        <br /> different parts of the world.
      </p>
      <div className="products-wrapper">
        {products.map((p) => (
          <div data-aos="fade-up" className="product">
            <img className="pd_img" src={p.img} alt={p.name} />
            <div className="products-overlay">
              <h1>{p.name}</h1>
              <h3>{p.desc}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
